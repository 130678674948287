import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import './Questions.css'
import SubmitResponse from "./SubmitResponse";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { IoIosImage, IoIosClose } from "react-icons/io";
import InstaProfile from "./InstaProfile";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import imageCompression from "browser-image-compression";
import { app } from "./firebase";
import { sendEmailVerification, signInAnonymously, signInWithEmailAndPassword, updateEmail, getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { FcNext } from "react-icons/fc";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import FloatingLabelInputStyled from "./FloatingLabelInputStyled";
import FloatingLabelInputStyledEmail from "./FloatingLabelInputStyledEmail";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// Validation Schema for form
const validationSchema = [
    Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
    }),
    Yup.object({
        otp: Yup.string().required("Required"),
    }),
    Yup.object({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
    }),
    Yup.object({
        photo: Yup.string().required("Profile photo is required"),
    }),
    Yup.object({
        dob: Yup.date().required("Required"),
    }),
    Yup.object({
        gender: Yup.string().oneOf(["Male", "Female"]).required("Required"),
    }),
    Yup.object({
        username: Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, "Invalid username")
            .required("Required"),
    }),
    Yup.object({
        instagram: Yup.string().required("Required"),
    }),
    // Yup.object({
    //     password: Yup.string().min(8, "Password must be at least 8 characters").required("Required"),
    //     confirmPassword: Yup.string()
    //         .oneOf([Yup.ref("password"), null], "Passwords must match")
    //         .required("Required"),
    // }),
];

const Questions = (props) => {
    const [step, setStep] = useState(0); // Manage which step we are on
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [serverMessage, setServerMessage] = useState('');
    const [direction, setDirection] = useState("next");  // Control animation direction
    const [isSubmitting, setIsSubmitting] = useState(false);
    const totalSteps = 8;
    // Initialize the Formik hook
    const [instagramData, setInstagramData] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    // useEffect(() => {
    //     // Fetch and convert the image into a Blob
    //     const fetchImage = async () => {
    //         try {
    //             console.log("iamge", imageSrc)
    //             const response = await fetch(imageSrc);
    //             const blob = await response.blob();
    //             const localUrl = URL.createObjectURL(blob);
    //             console.log("localUrl", localUrl)
    //             // Set the blob URL to the state to display it in the image tag
    //             setImageSrc(localUrl);

    //             // Clean up by revoking the object URL after a few seconds
    //             setTimeout(() => {
    //                 URL.revokeObjectURL(localUrl);  // This removes the local image
    //                 setImageSrc(null); // Clear the state after removal
    //             }, 5000); // Delete the image after 5 seconds
    //         } catch (error) {
    //             console.error('Error fetching the image:', error);
    //         }
    //     };

    //     fetchImage();
    // }, [instagramData]);

    const fetchInstagram = async (username) => {
        try {
            // const response = await fetch(`http://192.168.1.70:5000/get_profile_more_details?username=${username}`);
            const config = {
                url: `${process.env.REACT_APP_INSTAURL}/fetch_instagram_profile?username=${username}`,
                // url: `http://192.168.1.70:5000/get_profile_more_details?username=${username}`,
                method: 'get',
                redirect: 'follow',
                headers: {
                    'Content-Type': 'text/plain;charset=utf-8',
                }
            }

            const url = `${process.env.REACT_APP_INSTAURL}/download/${username}_profile_pic.jpg`;
            await axios.request(config).then(
                response => {
                    const data = response.data.data
                    const values = {
                        follower_count: data.user.edge_followed_by.count,
                        following_count: data.user.edge_follow.count,
                        full_name: data.user.full_name,
                        post_count: data.user.edge_owner_to_timeline_media.count,
                        profile_pic_url: url,
                        username: data.user.username
                    }
                    setInstagramData(values);
                    // console.log("prpURL", values.profile_pic_url)
                    setImageSrc(values.profile_pic_url);
                    // console.log(response.data)
                    // const values = {
                    //     follower_count: response.data.followers,
                    //     following_count: response.data.following,
                    //     full_name: response.data.full_name,
                    //     post_count: response.data.posts,
                    //     profile_pic_url: url,
                    //     username: response.data.username
                    // }
                    // setInstagramData(values);
                    // setImageSrc(url);
                }
            );
            // return response.data;


        } catch (error) {
            console.error("Error fetching Instagram data:", error);
            return null;
        }
    }


    const formik = useFormik({
        initialValues: {
            email: "",
            otp: "",
            firstName: "",
            lastName: "",
            photoURL: null,
            dob: "",
            gender: "",
            username: "",
            instagram: "",
            // password: "",
            // confirmPassword: "",
        },
        validationSchema: validationSchema[step],
        onSubmit: async (values) => {
            if (isSubmitting) return; // Prevent double submissions

            setIsSubmitting(true);
            // let photoURL = null;
            // const values2 = new URLSearchParams({
            //     email: "a@gmail.com",
            //     otp: "121",
            //     firstName: "1221",
            //     lastName: "121",
            //     dob: "1212",
            //     gender: "1221",
            //     username: "2112",
            //     instagram: "2121",
            // }).toString();
            // if (values.photo) {
            //     photoURL = await convertToBase64(values.photo);
            // }

            // const payload = {
            //     ...values,
            //     photoURL
            // };
            // console.log(values2)
            // console.log(values)
            const values2 = new URLSearchParams({
                ...values,
            }).toString();
            try {
                // Simulated submission - replace with real server call
                const config = {
                    url: `${process.env.REACT_APP_GOOGLE_SHEETS_URL}?${values2}`,
                    method: 'get',
                    redirect: 'follow',
                    headers: {
                        'Content-Type': 'text/plain;charset=utf-8',
                    }
                }
                const response = await axios.request(config);
                // On success
                console.log(response.status)
                setSubmissionStatus('success');
                setServerMessage('We will notify once your account is verified! Meanwhile let your followers know about it.');
            } catch (error) {
                setSubmissionStatus('error');
                setServerMessage('There was an issue submitting the form. Please try again.');
            }
        },
    });

    // Converts file to base64
    // const convertToBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => resolve(reader.result);
    //         reader.onerror = (error) => reject(error);
    //     });
    // };

    // Function to move to the next step
    const nextStep = () => {
        if (formik.isValid) {
            setDirection("next");  // Set animation direction
            setStep(step + 1);
        }
    };

    // Function to move to the previous step
    const previousStep = () => {
        setDirection("prev");  // Set animation direction

        setStep(step - 1);
    };

    // console.log(formik.values)


    const storage = getStorage(app);
    const [isUploading, setIsUploading] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const handleImageChange = async (event) => {
        const file = event.currentTarget.files[0];
        setIsUploading(true); // Start loading indicator
        setProfilePhoto(URL.createObjectURL(file));
        // Options for image compression
        const options = {
            maxSizeMB: 0.5,        // Maximum size in MB
            maxWidthOrHeight: 720, // Maximum width/height
            useWebWorker: true,  // Use multi-threading for compression
        };

        try {
            // Compress the image
            const compressedFile = await imageCompression(file, options);

            // Create a storage reference in Firebase
            const storageRef = ref(storage, `profile_images/${formik.values.email}`);

            // Upload the compressed image to Firebase
            const snapshot = await uploadBytes(storageRef, compressedFile);

            // Get the download URL of the uploaded image
            const downloadURL = await getDownloadURL(snapshot.ref);

            // Set the Firebase image URL in the Formik field
            formik.setFieldValue("photoURL", downloadURL);

        } catch (error) {
            console.error("Error uploading image: ", error);
        } finally {
            setIsUploading(false); // Stop loading indicator
        }
    };

    const Spinner = () => {
        return <div className="spinner"></div>;
    };

    const [timeLeft, setTimeLeft] = useState(120); // 2-minute timer
    const [verificationSent, setVerificationSent] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [stopNext, setStopNext] = useState(false);
    // Timer countdown function
    useEffect(() => {
        if (step === 1 && timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [timeLeft, step]);
    const auth = getAuth(app);

    // Function to create an anonymous user and send verification email
    const sendVerificationEmailFunc = async (email) => {
        try {
            const password = Math.random().toString(36).substring(7) + '@password.com';
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            await sendEmailVerification(userCredential.user);
            setVerificationSent(true);
            setStopNext(true);
            setStep(step + 1);
            startTimer();
        } catch (error) {
            console.error('Error sending verification email: ', error);
            if (error == "FirebaseError: Firebase: Error (auth/email-already-in-use).") {
                sendEmailOnly(email);
                setStep(step + 1);
            }
        }
    };

    const sendEmailOnly = async (email) => {
        try {
            await sendEmailVerification(auth.currentUser);
            setVerificationSent(true);
            // setStep(step + 1);
            setStopNext(true);
            startTimer();
        } catch (error) {
            console.error('Error sending verification email: ', error);
        }
    }

    const setTimer = (time) => {
        setTimeLeft(time);
    };

    // Start the 2-minute timer for email verification
    const startTimer = () => {
        const interval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 0) {
                    clearInterval(interval); // Stop the timer at 0
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000); // Decrement every second
    };

    // Check email verification
    const checkVerification = async () => {
        const user = auth.currentUser;
        await user.reload(); // Reload the user to get the updated emailVerified status
        if (user.emailVerified) {
            setEmailVerified(true)
            setStopNext(false);
        } else {
            alert('Email not verified yet. Please check your inbox.');
        }
    };

    // Function to render each form question
    const renderStep = () => {
        switch (step) {
            case 0:
                return (
                    <>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Email ID:</label> */}
                            <FloatingLabelInputStyledEmail
                                type="email"
                                name="email"
                                placeholderText="Email ID"
                                onChange={(e) => formik.setFieldValue("email", e.target.value)}
                                value={formik.values.email}
                                onClick={() => { sendVerificationEmailFunc(formik.values.email) }}
                            />
                            {formik.errors.email && formik.touched.email && <div>{formik.errors.email}</div>}
                        </div>
                        {/* <button type="button" onClick={() => { sendVerificationEmailFunc(formik.values.email) }}>Send Verification Email</button> */}
                    </>
                );
            case 1:
                return (
                    <div style={{ textAlign: 'left', height: 70 }}>
                        {!emailVerified && timeLeft != 0 && <>
                            {/* <label style={{ marginBottom: 10 }}>Waiting for email verification... </label> */}
                            <div>Time left: {timeLeft} seconds</div>
                            <button type="button" onClick={checkVerification} style={{ width: '100%', margin: 0, fontFamily: 'Montserrat, sans-serif' }}>
                                Check Verification
                            </button>
                        </>
                        }
                        {emailVerified && (
                            <div style={{ fontSize: "1.2em", textAlign: 'center', display: 'block', margin: 'auto', color: 'green' }}>
                                Email verified! Please proceed to the next step.
                            </div>
                        )}
                        {timeLeft === 0 && (
                            <>
                                <div style={{ fontSize: "1.2em", textAlign: 'center', display: 'block', margin: 'auto', color: 'red', marginBottom: 10 }}>
                                    Time limit exceeded! Please try again.
                                </div>
                                <button type="button" onClick={() => { sendEmailOnly(formik.values.email) }} style={{ margin: 0 }}>Send Verification Email</button>
                            </>
                        )}
                    </div>
                );
            case 2:
                return (
                    <div>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>First Name:</label> */}
                            {/* <input
                                type="text"
                                name="firstName"
                                onChange={formik.handleChange}
                                value={formik.values.firstName}
                            /> */}
                            <FloatingLabelInputStyled
                                placeholderText="First Name"
                                value={formik.values.firstName}
                                onChange={(e) => formik.setFieldValue("firstName", e.target.value)}
                                name="firstName"
                                type="text"
                            />
                            {formik.errors.firstName && formik.touched.firstName && <div>{formik.errors.firstName}</div>}
                        </div>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Last Name:</label> */}
                            <FloatingLabelInputStyled
                                type="text"
                                name="lastName"
                                placeholderText="Last Name"
                                onChange={(e) => formik.setFieldValue("lastName", e.target.value)}
                                value={formik.values.lastName}
                            />
                            {formik.errors.lastName && formik.touched.lastName && <div>{formik.errors.lastName}</div>}
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', }}>
                        <label>Date of Birth:</label>
                        <input
                            type="date"
                            name="dob"
                            onChange={formik.handleChange}
                            value={formik.values.dob}
                            style={{ textTransform: 'uppercase', textAlign: 'center' }}
                        />
                        {formik.errors.dob && formik.touched.dob && <div>{formik.errors.dob}</div>}
                    </div>
                );
            case 5:
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', }}>
                        <label>Gender:</label>
                        {/* <select
                            name="gender"
                            onChange={formik.handleChange}
                            value={formik.values.gender}
                        >
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select> */}
                        {/* gender radio buttons */}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                            <label style={{ margin: 2 }}>
                                Male
                            </label>
                            <input
                                type="radio"
                                name="gender"
                                onChange={formik.handleChange}
                                value="Male"
                                checked={formik.values.gender === "Male"}
                            />
                            <label style={{ margin: 2 }}>
                                Female
                            </label>
                            <input
                                type="radio"
                                name="gender"
                                onChange={formik.handleChange}
                                value="Female"
                                checked={formik.values.gender === "Female"}
                            />
                        </div>

                        {formik.errors.gender && formik.touched.gender && <div>{formik.errors.gender}</div>}
                    </div>
                );
            case 6:
                return (
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        {/* <label>Username:</label> */}
                        <FloatingLabelInputStyled
                            type="text"
                            name="username"
                            placeholderText="Username"
                            onChange={(e) => formik.setFieldValue("username", e.target.value)}
                            value={formik.values.username}
                        />
                        {formik.errors.username && formik.touched.username && <div>{formik.errors.username}</div>}
                    </div>
                );
            case 7:
                return (
                    <>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Instagram Username:</label> */}
                            <FloatingLabelInputStyled
                                type="text"
                                name="instagram"
                                placeholderText="Instagram Username"
                                onChange={(e) => formik.setFieldValue("instagram", e.target.value)}
                                value={formik.values.instagram}
                            />
                            {formik.errors.instagram && formik.touched.instagram && <div>{formik.errors.instagram}</div>}
                        </div>
                        {/* <button type="button" onClick={() => fetchInstagram(formik.values.instagram)}>Verify Instagram ID</button>
                        {instagramData && (
                            <InstaProfile profileData={instagramData} />
                        )} */}
                    </>
                );
            case 3:
                return (
                    <div style={{ position: 'relative', width: 120, height: 120, display: 'block', margin: 'auto', marginBottom: 40 }}>
                        {/* Profile Placeholder Image */}
                        <img
                            src={profilePhoto ? profilePhoto : 'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg'}
                            alt="Profile"
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                objectFit: 'cover',
                                border: '2px solid #ccc'
                            }}
                        />

                        {/* Pencil Icon for Edit */}
                        <label htmlFor="photo-upload">
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    backgroundColor: '#1b1b1b',
                                    borderRadius: '50%',
                                    padding: '10px',
                                    width: 25,
                                    height: 25,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    cursor: 'pointer'
                                }}
                            >
                                <IoIosImage size={25} color="#fff" />
                            </div>
                        </label>
                        <label style={{ display: 'flex', width: 200, textAlign: 'center', alignSelf: 'center', }}>Upload Photo</label>

                        {/* Hidden File Input */}
                        <input
                            id="photo-upload"
                            type="file"
                            name="photo"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />

                        {/* Error Message */}
                        {formik.errors.photo && formik.touched.photo && <div>{formik.errors.photo}</div>}
                    </div>

                );
            // case 8:
            //     setStep(step + 1);
            //     return (
            //         // <div>
            //         //     <label>Password:</label>
            //         //     <input
            //         //         type="password"
            //         //         name="password"
            //         //         onChange={formik.handleChange}
            //         //         value={formik.values.password}
            //         //     />
            //         //     {formik.errors.password && formik.touched.password && <div>{formik.errors.password}</div>}

            //         //     <label>Confirm Password:</label>
            //         //     <input
            //         //         type="password"
            //         //         name="confirmPassword"
            //         //         onChange={formik.handleChange}
            //         //         value={formik.values.confirmPassword}
            //         //     />
            //         //     {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            //         //         <div>{formik.errors.confirmPassword}</div>
            //         //     )}
            //         // </div>
            //     );
            default:
                return (
                    <div>
                        <p style={{ width: '100%' }}>You have reached the end of the form.</p>
                        <p style={{ width: '100%', fontSize: 14 }}>If you would like to review your responses before submitting please navigate below else press submit.</p>
                    </div>
                );
        }
    };
    // console.log(formik.isValid, formik.errors)
    return (
        <>
            <div className="form-container">
                {/* <h1 className="form-header">Register to Join Our Waitlist</h1> */}
                {/* Heading */}
                {/* Conditionally render either the form or submission response */}
                {submissionStatus ?
                    <SubmitResponse status={submissionStatus} message={serverMessage} />
                    : (
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={step}      // Use step as key to trigger re-render on change
                                timeout={300}   // Transition duration
                                classNames={direction === "next" ? "slide-next" : "slide-prev"}  // Animation classes based on direction
                                unmountOnExit
                            >
                                <form onSubmit={formik.handleSubmit}>
                                    {/* Use SwitchTransition for Next/Previous transitions */}

                                    <div>{renderStep()}</div>

                                    <div className="button-group">
                                        {step > 0 && (
                                            <button type="button" onClick={previousStep}>
                                                <FaArrowLeft size={25} color="grey" />
                                            </button>
                                        )}
                                        {step != 0 ?
                                            step < totalSteps - 1 ? (
                                                <button type="button" onClick={nextStep} disabled={!formik.isValid || stopNext}>
                                                    <FaArrowRight size={25} color="grey" />
                                                </button>
                                            ) : (
                                                <button type="submit" style={{ width: 100, backgroundColor: '#ccc', padding: 10, fontFamily: 'Montserrat, sans-serif' }}>Submit</button>
                                            )
                                            :
                                            null
                                        }

                                    </div>
                                </form>
                            </CSSTransition>
                        </SwitchTransition>
                    )}
                {/* Button to go back */}
                <button onClick={() => { props.setShow(!props.show) }} style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: 40,
                    height: 40,
                    background: '#fff',
                    color: '#000',
                    padding: '10px',
                    borderBottomLeftRadius: 25,
                    border: 'none',
                    cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}>
                    <IoIosClose size={50} />
                </button>
            </div>
        </>
    );
};

export default Questions;