import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Questions from "./Questions";
import BrandsHome from "./Brands/BrandsHome";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/brands" element={<BrandsHome />} />
      </Routes>
    </BrowserRouter>
  );
}


