import React, { useEffect, useState } from 'react';
import './BrandsHome.css';
import mockup from '../assets/shop-phone.png';
import personPhone from '../assets/photo-bg.png';
import Marquee from "react-fast-marquee";
import BrandsQuestions from './BrandsQuestions';
import { CSSTransition } from 'react-transition-group';
import GlitchText from '../GlitchText';
import GlitchText2 from '../GlitchText2';


const Navbar = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // This function will update the state with the current window width
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    // Add event listener on mount and cleanup on unmount
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <nav className="navbar">
            {/* if mobile render this */}
            {windowWidth < 500 && (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div className="navbar-logo">
                            <GlitchText text="lynkd" />
                            <span style={{ fontSize: "0.65em", display: 'block', margin: 'auto', justifyContent: 'flex-end', display: 'flex' }}>Brands</span>
                        </div>
                        <div className="navbar-buttons">
                            <button className="get-started">Contact</button>
                        </div>
                    </div>
                    <Marquee className="marquee">
                        NYKAA | LENSKART | MAMAEARTH | SHEIN | WARBY PARKER | GLOSSIER | ALLBIRDS | CASPER | GYMSHARK | TATA CLIQ | FYN | CARS24 | URBAN LADDER | EVERLANE | REFORMATION | ZILINGO | BOMBAY SHAVING COMPANY | THE MAN COMPANY | MYGLAMM | ZIVAME | BARKBOX | MEUNDIES | NAADAM | ALO YOGA | STITCH FIX | ROTHY'S | BONOBOS | OUTDOOR VOICES | HIMS & HERS | GRANA | SOKO | FABLETICS | POP & SUKI | CARAA | VRAI | HUSH PUPPIES | CUYANA | M.GEMI | ETTITUDE | BANDO | THE SILL | WOKE UP LIKE THIS | GOSSAMER | ZARA HOME | HATCH | CHUMBAK | HAPIPOLA | PORTEA | BIRA 91 | LULA'S
                    </Marquee>
                </>
            )}
            {/* if desktop render this */}
            {windowWidth > 500 && (
                <>
                    <div className="navbar-logo">
                        <GlitchText text="lynkd" />
                        <span style={{ fontSize: "0.65em", display: 'block', margin: 'auto', justifyContent: 'flex-end', display: 'flex' }}><GlitchText2 text="brands" /></span>
                    </div>
                    <Marquee className="marquee">
                        NYKAA | LENSKART | MAMAEARTH | SHEIN | WARBY PARKER | GLOSSIER | ALLBIRDS | CASPER | GYMSHARK | TATA CLIQ | FYN | CARS24 | URBAN LADDER | EVERLANE | REFORMATION | ZILINGO | BOMBAY SHAVING COMPANY | THE MAN COMPANY | MYGLAMM | ZIVAME | BARKBOX | MEUNDIES | NAADAM | ALO YOGA | STITCH FIX | ROTHY'S | BONOBOS | OUTDOOR VOICES | HIMS & HERS | GRANA | SOKO | FABLETICS | POP & SUKI | CARAA | VRAI | HUSH PUPPIES | CUYANA | M.GEMI | ETTITUDE | BANDO | THE SILL | WOKE UP LIKE THIS | GOSSAMER | ZARA HOME | HATCH | CHUMBAK | HAPIPOLA | PORTEA | BIRA 91 | LULA'S
                    </Marquee>
                    <div className="navbar-buttons">
                        <button className="get-started">Contact</button>
                    </div>
                </>
            )}
        </nav>
    );
}
const stats = [
    { label: "Active creators", value: "5000+" },
    { label: "Number of Brands", value: "800" },
    { label: "Impressions", value: "1M+" },
];

const MainSection = () => {
    const [getStarted, setGetStarted] = useState(false);
    const [showMiddle, setShowMiddle] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowMiddle(true);
            setShow(true);
        }, 500);
    }, []);

    return (
        <section className="main-section">
            <div className="main-content">
                <CSSTransition
                    in={show}
                    timeout={1000}
                    classNames="slide-left"
                >
                    <div className="left-section">
                        <img className="main-image" src={personPhone} alt="Responsible Banking" />
                    </div>
                </CSSTransition>
                <div className="middle-section">
                    <CSSTransition
                        in={showMiddle}
                        timeout={showMiddle ? 9000 : 300}
                        classNames={'slide'}
                        unmountOnExit
                        onExited={() => setGetStarted(true)}
                    >
                        <div>
                            <h1 className='middle-heading-brands'>Designed as a social marketplace for D2C brands globally</h1>
                            <p>Lynkd blends social media with e-commerce, giving creators an edge to earn more than youtube through exciting engagement with followers with brands they relate to.- Lynkd is a tool  that allows you to create your shop, seamlessly connect products and launches to creators, and manage your marketing spend effectively, ensuring minimal customer acquisition costs (CAC).</p>
                            <div className="register-btn">
                                <button onClick={() => { setShowMiddle(false); }}>Get Started</button>
                            </div>
                            <div className='stats-container'>
                                {stats.map((stat, index) => (
                                    <div key={index} className='stats-item'>
                                        <div className='stats-label'>
                                            {stat.label}
                                        </div>
                                        <div className='stats-value'>
                                            {stat.value}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </CSSTransition>
                    <CSSTransition
                        in={getStarted}
                        timeout={600}
                        classNames={'slide-fast'}
                        unmountOnExit
                        onExited={() => setShowMiddle(true)}
                    >
                        <BrandsQuestions show={getStarted} setShow={setGetStarted} />
                    </CSSTransition>
                </div>
                <CSSTransition
                    in={show}
                    timeout={800}
                    classNames="slide-right"
                >
                    <div className="right-section">
                        <img className="side-image" src={mockup} alt="Responsible Banking" />
                    </div>
                </CSSTransition>
            </div>
        </section>
    )
};

const BrandsHome = () => (
    <div className="Brands">
        <Navbar />
        <MainSection />
    </div>
);

export default BrandsHome;