import React, { useState, useEffect } from "react";
import "./FloatingLabelInputStyled.css";
import { BiChevronRight, BiChevronRightCircle, BiRightArrow } from "react-icons/bi";
import { FaArrowCircleRight, FaArrowRight } from "react-icons/fa";

const FloatingLabelInputStyledEmail = (props) => {
    const [value, setValue] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (props.value !== "") {
            setIsFocused(true);
        } else {
            setIsFocused(false);
        }
    }, [props.value]);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        if (props.value === "") {
            setIsFocused(false);
        }
    };

    const handleChange = (e) => {
        setValue(e.target.value);
        if (props.onChange) {
            props.onChange(e);
        }
    };

    return (
        <div className="floating-label-input-container" style={{ width: props.width ? props.width : '100%' }}>
            <label
                className={`floating-label ${isFocused || value ? "focused" : ""}`}
            >
                {props.placeholderText}
            </label>
            <input
                type={props.type || "text"}
                value={props.value || value}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className="floating-input"
                maxLength={props.maxLength}
                onClick={null}
            />
            <div className="floatingEmail-icon">
                <FaArrowRight size={23} color="grey" onClick={() => { props.onClick(); console.log('clicked') }} />
            </div>
        </div>
    );
};

export default FloatingLabelInputStyledEmail;
