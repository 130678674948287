import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import './BrandsQuestions.css'
import SubmitResponse from "../SubmitResponse";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { IoIosClose, IoIosImage } from "react-icons/io";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import imageCompression from "browser-image-compression";
import { app } from "../firebase";
import { sendEmailVerification, getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import FloatingLabelInputStyledEmail from "../FloatingLabelInputStyledEmail";
import FloatingLabelInputStyled from "../FloatingLabelInputStyled";

// Validation Schema for form

const validationSchema = Yup.object({
    brandName: Yup.string()
        .min(3, "Brand Name must be at least 3 characters")
        .required("Brand Name is required"),

    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),

    username: Yup.string()
        .min(3, "Username must be at least 3 characters")
        .required("Username is required"),

    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),

    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords must match")
        .required("Confirm Password is required"),

    phoneNumber: Yup.string()
        .matches(/^\+?[1-9]\d{1,14}$/, "Invalid phone number format")
        .required("Phone Number is required"),

    photo: Yup.mixed()
        .required("Profile Photo is required"),

    storeLink: Yup.string()
        .url("Invalid URL format")
        .required("Store Link is required"),

    instagramLink: Yup.string()
        .url("Invalid Instagram URL format")
        .nullable(),

    facebookLink: Yup.string()
        .url("Invalid Facebook URL format")
        .nullable(),

    twitterLink: Yup.string()
        .url("Invalid Twitter URL format")
        .nullable(),

    documents: Yup.mixed()
        .required("KYC Document is required"),

    taxID: Yup.string()
        .matches(/^[A-Z0-9]{8,15}$/, "Invalid Tax ID format")
        .required("Tax ID is required"),
});


const BrandsQuestions = (props) => {
    const [step, setStep] = useState(0); // Manage which step we are on
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [serverMessage, setServerMessage] = useState('');
    const [direction, setDirection] = useState("next");  // Control animation direction
    const [isSubmitting, setIsSubmitting] = useState(false);
    const totalSteps = 10;
    // Initialize the Formik hook
    const [instagramData, setInstagramData] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [uploadedDone, setUploadedDone] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            brandName: '',
            username: '',
            // password: '',
            // confirmPassword: '',
            phoneNumber: '',
            storeLink: '',
            instagramLink: '',
            facebookLink: '',
            twitterLink: '',
            documents: [],
            taxID: '',
            photo: '',
        },
        validationSchema: validationSchema[step],
        onSubmit: async (values) => {
            if (isSubmitting) return; // Prevent double submissions
            console.log(values);
            setIsSubmitting(true);
            const values2 = new URLSearchParams({
                ...values,
            }).toString();
            try {
                // Simulated submission - replace with real server call
                const config = {
                    url: `${process.env.REACT_APP_GOOGLE_SHEETS_URL2}?${values2}`,
                    method: 'get',
                    redirect: 'follow',
                    headers: {
                        'Content-Type': 'text/plain;charset=utf-8',
                    }
                }
                const response = await axios.request(config);
                // On success
                console.log(response.status)
                setSubmissionStatus('success');
                setServerMessage('We will notify once your account is verified! Meanwhile let your followers know about it.');
            } catch (error) {
                setSubmissionStatus('error');
                setServerMessage('There was an issue submitting the form. Please try again.');
            }
        },
    });

    // Function to move to the next step
    const nextStep = () => {
        if (formik.isValid) {
            setDirection("next");  // Set animation direction
            setStep(step + 1);
        }
    };

    // Function to move to the previous step
    const previousStep = () => {
        setDirection("prev");  // Set animation direction

        setStep(step - 1);
    };

    // console.log(formik.values)


    const storage = getStorage(app);
    const [isUploading, setIsUploading] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const handleImageChange = async (event) => {
        const file = event.currentTarget.files[0];
        setIsUploading(true); // Start loading indicator
        setProfilePhoto(URL.createObjectURL(file));
        // Options for image compression
        const options = {
            maxSizeMB: 0.5,        // Maximum size in MB
            maxWidthOrHeight: 720, // Maximum width/height
            useWebWorker: true,  // Use multi-threading for compression
        };

        try {
            // Compress the image
            const compressedFile = await imageCompression(file, options);

            // Create a storage reference in Firebase
            const storageRef = ref(storage, `brand_images/${formik.values.brandName}`);

            // Upload the compressed image to Firebase
            const snapshot = await uploadBytes(storageRef, compressedFile);

            // Get the download URL of the uploaded image
            const downloadURL = await getDownloadURL(snapshot.ref);

            // Set the Firebase image URL in the Formik field
            formik.setFieldValue("photo", downloadURL);

        } catch (error) {
            console.error("Error uploading image: ", error);
        } finally {
            setIsUploading(false); // Stop loading indicator
        }
    };

    const handleDocumentUpload = async (file) => {
        setIsUploading(true); // Start loading indicator
        try {
            if (!file) return null;

            const storageRef = ref(storage, `brand_documents/${formik.values.brandName}/${file.name}`);

            // upload document to firebase
            const snapshot = await uploadBytes(storageRef, file);

            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;

        } catch (error) {
            console.error("Error uploading image: ", error);
        } finally {
            setIsUploading(false); // Stop loading indicator
        }
    };

    const Spinner = () => {
        return <div className="spinner"></div>;
    };

    const [timeLeft, setTimeLeft] = useState(120); // 2-minute timer
    const [verificationSent, setVerificationSent] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [stopNext, setStopNext] = useState(false);
    const [documents, setDocuments] = useState([{ title: '', file: null }]);

    // Timer countdown function
    useEffect(() => {
        if (step === 1 && timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [timeLeft, step]);
    const auth = getAuth(app);

    // Function to create an anonymous user and send verification email
    const sendVerificationEmailFunc = async (email) => {
        try {
            const password = Math.random().toString(36).substring(7) + '@password.com';
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            await sendEmailVerification(userCredential.user);
            setVerificationSent(true);
            setStopNext(true);
            setStep(step + 1);
            startTimer();
        } catch (error) {
            console.error('Error sending verification email: ', error);
            if (error == "FirebaseError: Firebase: Error (auth/email-already-in-use).") {
                sendEmailOnly(email);
                setStep(step + 1);
            }
        }
    };

    const sendEmailOnly = async (email) => {
        try {
            await sendEmailVerification(auth.currentUser);
            setVerificationSent(true);
            // setStep(step + 1);
            setStopNext(true);
            startTimer();
        } catch (error) {
            console.error('Error sending verification email: ', error);
        }
    }

    const setTimer = (time) => {
        setTimeLeft(time);
    };

    // Start the 2-minute timer for email verification
    const startTimer = () => {
        const interval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 0) {
                    clearInterval(interval); // Stop the timer at 0
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000); // Decrement every second
    };

    // Check email verification
    const checkVerification = async () => {
        const user = auth.currentUser;
        await user.reload(); // Reload the user to get the updated emailVerified status
        if (user.emailVerified) {
            setEmailVerified(true)
            setStopNext(false);
        } else {
            alert('Email not verified yet. Please check your inbox.');
        }
    };

    const uploadDocuments = async () => {
        const uploadedDocuments = [];
        setStopNext(false);
        setIsUploading(true);
        setUploadedDone(false);
        for (const document of documents) {
            if (document.file) {
                const url = await handleDocumentUpload(document.file);
                uploadedDocuments.push({
                    title: document.title,
                    url: url,
                });
            }
        }

        // Set the uploaded documents in Formik field with both title and URL
        formik.setFieldValue('documents', JSON.stringify(uploadedDocuments));
        setStopNext(false);
        setIsUploading(false);
        setUploadedDone(true);
    };

    // Function to render each form question
    const renderStep = () => {
        switch (step) {
            case 0:
                return (
                    <>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Email ID:</label> */}
                            <FloatingLabelInputStyledEmail
                                type="email"
                                name="email"
                                placeholderText="Email ID"
                                onChange={(e) => formik.setFieldValue("email", e.target.value)}
                                value={formik.values.email}
                                onClick={() => { sendVerificationEmailFunc(formik.values.email) }}
                            />
                            {formik.errors.email && formik.touched.email && <div>{formik.errors.email}</div>}
                        </div>
                        {/* <button type="button" onClick={() => { sendVerificationEmailFunc(formik.values.email) }}>Send Verification Email</button> */}
                    </>
                );
            case 1:
                return (
                    <div style={{ textAlign: 'left' }}>
                        {!emailVerified && timeLeft != 0 && <>
                            <label style={{ marginBottom: 10 }}>Waiting for email verification... </label>
                            <div>Time left: {timeLeft} seconds</div>
                            <button type="button" onClick={checkVerification} style={{ width: '100%', margin: 0 }}>
                                Check Verification
                            </button>
                        </>
                        }
                        {emailVerified && (
                            <div style={{ fontSize: "1.2em", textAlign: 'center', display: 'block', margin: 'auto', color: 'green' }}>
                                Email verified! Please proceed to the next step.
                            </div>
                        )}
                        {timeLeft === 0 && (
                            <>
                                <div style={{ fontSize: "1.2em", textAlign: 'center', display: 'block', margin: 'auto', color: 'red', marginBottom: 10 }}>
                                    Time limit exceeded! Please try again.
                                </div>
                                <button type="button" onClick={() => { sendEmailOnly(formik.values.email) }}>Send Verification Email</button>
                            </>
                        )}
                    </div>
                );
            case 2:
                return (
                    <>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Brand Name:</label> */}
                            {/* <input
                                type="text"
                                name="brandName"
                                onChange={formik.handleChange}
                                value={formik.values.brandName}
                            /> */}
                            <FloatingLabelInputStyled
                                placeholderText="Brand Name"
                                value={formik.values.brandName}
                                onChange={(e) => formik.setFieldValue("brandName", e.target.value)}
                                name="brandName"
                                type="text"
                            />
                            {formik.errors.brandName && formik.touched.brandName && <div>{formik.errors.brandName}</div>}
                        </div>
                    </>
                );
            case 3:
                return (
                    <>
                        <div style={{ position: 'relative', width: 150, height: 150, display: 'block', margin: 'auto', marginBottom: 40 }}>
                            {/* Profile Placeholder Image */}
                            <img
                                src={profilePhoto ? profilePhoto : 'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg'}
                                alt="Profile"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    border: '2px solid #ccc'
                                }}
                            />

                            {/* Pencil Icon for Edit */}
                            <label htmlFor="photo-upload">
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                        backgroundColor: '#1b1b1b',
                                        borderRadius: '50%',
                                        padding: '10px',
                                        width: 35,
                                        height: 35,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <IoIosImage size={25} color="#fff" />
                                </div>
                            </label>
                            <label style={{ display: 'flex', width: 200, textAlign: 'center', alignSelf: 'center', marginLeft: -10 }}>Upload Brand Logo</label>

                            {/* Hidden File Input */}
                            <input
                                id="photo-upload"
                                type="file"
                                name="photo"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                            />

                            {/* Error Message */}
                            {formik.errors.photo && formik.touched.photo && <div>{formik.errors.photo}</div>}
                        </div>
                    </>
                );
            case 4:
                return (
                    <>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Username:</label> */}
                            <FloatingLabelInputStyled
                                placeholderText="Username"
                                value={formik.values.username}
                                onChange={(e) => formik.setFieldValue("username", e.target.value)}
                                name="username"
                                type="text"
                            />
                            {formik.errors.username && formik.touched.username && <div>{formik.errors.username}</div>}
                        </div>
                    </>
                );
            case 5:
                return (
                    <>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Phone Number:</label> */}
                            {/* <input
                                type="text"
                                name="phoneNumber"
                                onChange={formik.handleChange}
                                value={formik.values.phoneNumber}
                            /> */}
                            <FloatingLabelInputStyled
                                placeholderText="Phone Number"
                                value={formik.values.phoneNumber}
                                onChange={(e) => formik.setFieldValue("phoneNumber", e.target.value)}
                                name="phoneNumber"
                                type="text"
                            />
                            {formik.errors.phoneNumber && formik.touched.phoneNumber && <div>{formik.errors.phoneNumber}</div>}
                        </div>
                    </>
                );
            case 6:
                return (
                    <>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Store Link:</label> */}
                            {/* <input
                                type="url"
                                name="storeLink"
                                onChange={formik.handleChange}
                                value={formik.values.storeLink}
                            /> */}
                            <FloatingLabelInputStyled
                                placeholderText="Store Link"
                                value={formik.values.storeLink}
                                onChange={(e) => formik.setFieldValue("storeLink", e.target.value)}
                                name="storeLink"
                                type="text"
                            />
                            {formik.errors.storeLink && formik.touched.storeLink && <div>{formik.errors.storeLink}</div>}
                        </div>
                    </>
                );
            case 7:
                return (
                    <>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* Social Media Links */}
                            {/* <label>Instagram Profile:</label> */}
                            {/* <input
                                type="url"
                                name="instagramLink"
                                onChange={formik.handleChange}
                                value={formik.values.instagramLink}
                            /> */}
                            <FloatingLabelInputStyled
                                placeholderText="Instagram Link"
                                value={formik.values.instagramLink}
                                onChange={(e) => formik.setFieldValue("instagramLink", e.target.value)}
                                name="instagramLink"
                                type="text"
                            />
                            {formik.errors.instagramLink && formik.touched.instagramLink && <div>{formik.errors.instagramLink}</div>}
                        </div>

                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Facebook Link:</label> */}
                            {/* <input
                                type="url"
                                name="facebookLink"
                                onChange={formik.handleChange}
                                value={formik.values.facebookLink}
                            /> */}
                            <FloatingLabelInputStyled
                                placeholderText="Facebook Link"
                                value={formik.values.facebookLink}
                                onChange={(e) => formik.setFieldValue("facebookLink", e.target.value)}
                                name="facebookLink"
                                type="text"
                            />
                            {formik.errors.facebookLink && formik.touched.facebookLink && <div>{formik.errors.facebookLink}</div>}
                        </div>

                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Twitter Profile:</label> */}
                            {/* <input
                                type="url"
                                name="twitterLink"
                                onChange={formik.handleChange}
                                value={formik.values.twitterLink}
                            /> */}
                            <FloatingLabelInputStyled
                                placeholderText="Twitter Link"
                                value={formik.values.twitterLink}
                                onChange={(e) => formik.setFieldValue("twitterLink", e.target.value)}
                                name="twitterLink"
                                type="text"
                            />
                            {formik.errors.twitterLink && formik.touched.twitterLink && <div>{formik.errors.twitterLink}</div>}
                        </div>
                    </>
                );
            case 8:
                return (
                    <>
                        <h4>Upload KYC Documents:</h4>
                        {documents.map((document, index) => (
                            <div key={index} style={{ display: 'flex', marginBottom: '10px', gap: 10 }}>
                                {/* <input
                                    type="text"
                                    placeholder="Document Title"
                                    value={document.title}
                                    onChange={(e) => handleDocumentChange(index, 'title', e.target.value)}
                                /> */}
                                <FloatingLabelInputStyled
                                    placeholderText="Document Title"
                                    value={document.title}
                                    onChange={(e) => handleDocumentChange(index, 'title', e.target.value)}
                                    name="documentTitle"
                                    type="text"
                                />
                                <input
                                    type="file"
                                    onChange={(e) => handleDocumentChange(index, 'file', e.target.files[0])}
                                />
                            </div>
                        ))}
                        <button type="button" onClick={addDocumentField} >+ Add Another Document</button>
                        <button type="button" onClick={removeDocumentField}>- Remove Document</button>
                        {/* Button to trigger file upload to Firebase */}
                        <button type="button" onClick={uploadDocuments} style={{ background: 'black', color: 'white' }}>
                            Confirm and Upload Documents
                        </button>
                        {!isUploading && !stopNext && !uploadedDone && <div>Documents pending to be uploaded!</div>}
                        {isUploading && <div>Documents uploading.</div>}
                        {!stopNext && !isUploading && uploadedDone && <div style={{ color: 'green' }}>Documents uploaded successfully!</div>}
                    </>
                );
            case 9:
                return (
                    <>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Tax ID:</label> */}
                            {/* <input
                                type="text"
                                name="taxID"
                                onChange={formik.handleChange}
                                value={formik.values.taxID}
                            /> */}
                            <FloatingLabelInputStyled
                                placeholderText="Tax ID"
                                value={formik.values.taxID}
                                onChange={(e) => formik.setFieldValue("taxID", e.target.value)}
                                name="taxID"
                                type="text"
                            />
                            {formik.errors.taxID && formik.touched.taxID && <div>{formik.errors.taxID}</div>}
                        </div>
                    </>
                );
            default:
                return <div>Unknown step</div>;
        }
    };

    // Handle adding more document fields
    const addDocumentField = () => {
        setDocuments([...documents, { title: '', file: null }]);
        // upload to fire base

    };

    // Handle removing document fields
    const removeDocumentField = () => {
        if (documents.length > 1) {
            const updatedDocuments = [...documents];
            updatedDocuments.pop();
            setDocuments(updatedDocuments);
        }
    }
    // Handle document field change
    const handleDocumentChange = (index, field, value) => {
        const updatedDocuments = [...documents];
        updatedDocuments[index][field] = value;
        setDocuments(updatedDocuments);
        formik.setFieldValue('documents', updatedDocuments);
    };

    // console.log(formik.isValid, formik.errors)
    return (
        <>
            <div className="form-container">
                {/* <h1 className="form-header">Register to Join Our Waitlist</h1> */}
                {/* Heading */}
                {/* Conditionally render either the form or submission response */}
                {submissionStatus ?
                    <SubmitResponse status={submissionStatus} message={serverMessage} />
                    : (
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={step}      // Use step as key to trigger re-render on change
                                timeout={300}   // Transition duration
                                classNames={direction === "next" ? "slide-next" : "slide-prev"}  // Animation classes based on direction
                                unmountOnExit
                            >
                                <form onSubmit={formik.handleSubmit}>
                                    {/* Use SwitchTransition for Next/Previous transitions */}

                                    <div>{renderStep()}</div>

                                    <div className="button-group">
                                        {step > 0 && (
                                            <button type="button" onClick={previousStep}>
                                                <BsChevronLeft size={30} color="#000" />
                                            </button>
                                        )}
                                        {step != 0 ?
                                            step < totalSteps - 1 ? (
                                                <button type="button" onClick={nextStep} disabled={!formik.isValid || stopNext}>
                                                    <BsChevronRight size={30} color="#000" />
                                                </button>
                                            ) : (
                                                <button type="submit">Submit</button>
                                            )
                                            :
                                            null
                                        }

                                    </div>
                                </form>
                            </CSSTransition>
                        </SwitchTransition>
                    )}
                {/* Button to go back */}
                <button onClick={() => { props.setShow(!props.show) }} style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: 40,
                    height: 40,
                    background: '#fff',
                    color: '#000',
                    padding: '10px',
                    borderBottomLeftRadius: 25,
                    border: 'none',
                    cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}>
                    <IoIosClose size={50} />
                </button>
            </div>
        </>
    );
};

export default BrandsQuestions;