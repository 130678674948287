import React from 'react';
import './GlitchText2.css';

const GlitchText2 = ({ text }) => {
    return (
        <div className="glitch-container2">
            <span className="glitch-text2" data-text={text}>
                {text}
            </span>
        </div>
    );
};

export default GlitchText2;
