import React from 'react';
import './InstaProfile.css';

const InstaProfile = ({ profileData }) => {
    const { follower_count, following_count, full_name, post_count, profile_pic_url, username } = profileData;

    // Format follower and post counts to have commas
    const formatCount = (num) => {
        return num;
    };

    console.log(profile_pic_url)

    return (
        <div className="profile-container">
            <div className="profile-header">
                <img src={profile_pic_url} alt={`${username}'s profile`} className="profile-pic" />
                <div className="profile-stats">
                    <div className="stat-item">
                        <span className="stat-number">{formatCount(post_count)}</span>
                        <span className="stat-label">Posts</span>
                    </div>
                    <div className="stat-item">
                        <span className="stat-number">{formatCount(follower_count)}</span>
                        <span className="stat-label">Followers</span>
                    </div>
                    <div className="stat-item">
                        <span className="stat-number">{formatCount(following_count)}</span>
                        <span className="stat-label">Following</span>
                    </div>
                </div>
            </div>
            <div className="profile-info">
                <h2 className="full-name">{full_name}</h2>
                <h3 className="username">@{username}</h3>
            </div>
        </div>
    );
};

export default InstaProfile;
